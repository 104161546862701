// Translations
export const translations = {
  // language / country / property
  en: {
    gbr: {
      locality: 'Town/City',
      region: 'County',
      postal_code: 'Post code'
    },
    usa: {
      locality: 'City',
      region: 'State',
      postal_code: 'ZIP code'
    }
  }
  // Add other languages below
};